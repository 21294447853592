import React from 'react';
import ReactDOM from 'react-dom';
import Homepage from "./Homepage"

ReactDOM.render(
  <React.StrictMode>
    <Homepage />
  </React.StrictMode>,
  document.getElementById('root') 
);


